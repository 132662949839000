import React from "react";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Modal({ children, handleClose }) {
  return (
    <div className="absolute w-full px-4 py-4 bg-white/90 min-h-[80dvh] z-50">
      <div className="relative border shadow-2xl rounded-xl md:my-16 md:w-1/2 mx-auto bg-white">
        <FontAwesomeIcon
          icon={faTimesCircle}
          size="lg"
          className="absolute text-black cursor-pointer top-2 right-2 hover:opacity-70 md:top-5 md:right-5"
          role="button"
          onClick={() => {
            handleClose();
          }}
        />
        <div className="mx-4 my-8">{children}</div>
      </div>
    </div>
  );
}
