import useInitializeStage from "../../hooks/useInitializeStage";
import useLoadFile from "../../hooks/useLoadFile";

export default function MoleculeViewer() {
  const stageRef = useInitializeStage();
  const setFile = useLoadFile(stageRef);

  return (
    <div className="flex flex-col space-y-4 cursor-pointer">
      <label className="font-semibold text-xl" htmlFor="file">
        Upload .pdb or .pdbqt file
      </label>
      <input
        id="file"
        name="file"
        type="file"
        accept=".pdb,.pdbqt"
        onChange={({ target }) => {
          if (target.files[0]) setFile(target.files[0]);
        }}
        className="mb-2 text-sm text-gray-700 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
      />
      <div
        id="viewport"
        className="w-full h-96 border-2 border-gray-200 rounded-md shadow-inner"></div>
    </div>
  );
}
