export default function CancelButton({ isLoading, handleClick }) {
  return (
    <button
      className="rounded-lg px-1 py-2 enabled:hover:bg-red-600 disabled:cursor-not-allowed duration-200 w-fit enabled:hover:text-white"
      disabled={isLoading}
      onClick={handleClick}>
      Cancel
    </button>
  );
}
