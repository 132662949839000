import ToolsAside from "../components/ToolsAside";
import organisms from "../assets/gras.json";
import { useState } from "react";

export default function GRASOrganism() {
  const [filterGRN, setFilterGRN] = useState("");
  const [filterSubstance, setFilterSubstance] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const filteredOrganisms = organisms.filter(
    (organism) =>
      organism.GRAS.includes(filterGRN) &&
      organism.Substance.toLowerCase().includes(filterSubstance.toLowerCase())
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredOrganisms.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredOrganisms.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <main className="max-w-7xl mx-auto p-8 md:flex md:space-x-6 space-y-6 md:space-y-0 min-h-[59dvh]">
      <ToolsAside id={9} />
      <div className="space-y-10 w-full">
        <h2 className="text-4xl font-bold">GRAS Organism List</h2>

        <div className="space-y-4 w-full">
          <div className="space-y-2">
            <label className="font-semibold" htmlFor="filterGRN">
              Filter by GRN
            </label>
            <input
              id="filterGRN"
              name="filterGRN"
              type="text"
              placeholder="Enter GRN Number"
              value={filterGRN}
              onChange={(e) => {
                setFilterGRN(e.target.value);
                setCurrentPage(1);
              }}
              className="border p-2 w-full rounded-md"
            />
          </div>
          <div className="space-y-2">
            <label className="font-semibold" htmlFor="filterSubstance">
              Filter by Substance
            </label>
            <input
              id="filterSubstance"
              name="filterSubstance"
              type="text"
              placeholder="Enter Substance"
              value={filterSubstance}
              onChange={(e) => {
                setFilterSubstance(e.target.value);
                setCurrentPage(1);
              }}
              className="border p-2 w-full rounded-md"
            />
          </div>
        </div>

        <section className="space-y-4 w-full">
          <table className="w-full border border-gray-400">
            <thead>
              <tr>
                <th className="border p-2">GRAS Notice (GRN) No.</th>
                <th className="border p-2">Substance</th>
                <th className="border p-2">Intended Use</th>
                <th className="border p-2">Basis</th>
                <th className="border p-2">Date</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((organism, index) => (
                <tr key={index}>
                  <td className="border p-2 text-balance">{organism.GRAS}</td>
                  <td className="border p-2 text-balance">
                    {organism.Substance}
                  </td>
                  <td className="border p-2 text-balance">{organism.Use}</td>
                  <td className="border p-2 text-balance">{organism.Basis}</td>
                  <td className="border p-2 text-balance">{organism.Date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>

        <div className="flex justify-between items-center mt-4">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className="p-2 border rounded-md bg-gray-200 disabled:opacity-50 w-1/4 enabled:hover:bg-gray-400 duration-200 disabled:cursor-not-allowed">
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="p-2 border rounded-md bg-gray-200 disabled:opacity-50 w-1/4 enabled:hover:bg-gray-400 duration-200 disabled:cursor-not-allowed">
            Next
          </button>
        </div>

        <section className="space-y-4">
          <p className="text-sm">
            Downloaded from FDA GRAS Notices;{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="http://www.cfsanappsexternal.fda.gov/scripts/fdcc/?set=GRASNotices"
              className="text-blue-600 underline">
              http://www.cfsanappsexternal.fda.gov/scripts/fdcc/?set=GRASNotices
            </a>
            ; Last updated 4/11/2024; downloaded 4/16/2024.
          </p>
        </section>
      </div>
    </main>
  );
}
