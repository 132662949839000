import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../views/Login";
import RegisterClient from "../views/RegisterClient";
import FormRecover from "../views/FormRecover";
import ConfirmEmail from "../views/ConfirmEmail";
import ValidateEmail from "../views/ValidateEmail";
import RecoverPassword from "../views/RecoverPassword";

export default function NotLoggedInRoutes({ setUserInformation }) {
  return (
    <Routes>
      <Route path="*" element={<Login />} />
      <Route
        path="/"
        element={<Login setUserInformation={setUserInformation} />}
      />
      <Route
        path="/login"
        element={<Login setUserInformation={setUserInformation} />}
      />
      <Route path="/sign_up" element={<RegisterClient />} />
      <Route path="/forgot_password" element={<FormRecover />} />
      <Route path="/confirm" element={<ConfirmEmail />} />
      <Route path="/restore_password/:code" element={<RecoverPassword />} />
      <Route path="/confirm/:code" element={<ValidateEmail />} />
    </Routes>
  );
}
