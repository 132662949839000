import Skeleton from "../ui/Skeleton";

export default function ProjectNotesDisplay({
  projectDetails,
  isLoadingInformation,
  setCanEditNotes,
}) {
  return isLoadingInformation ? (
    <div className="flex items-center space-x-2">
      <p className="text-xl">Description:</p>
      <Skeleton className="h-5 w-2/3 bg-slate-300" />
    </div>
  ) : (
    <div className="flex items-center space-x-6">
      <p className="text-xl">Description: {projectDetails?.notes}</p>
      <img
        src="https://storage.googleapis.com/gpp4-bucket/rsc/rsc/pencil.svg"
        alt="Edit button to change project notes"
        className={`w-7 h-7 ${
          isLoadingInformation ? "cursor-not-allowed" : "cursor-pointer"
        } rounded-lg hover:bg-gray-200 p-1 duration-200`}
        onClick={() => {
          if (!isLoadingInformation) setCanEditNotes(true);
        }}
      />
    </div>
  );
}
