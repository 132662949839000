import SequenceResult from "./SequenceResult";

export default function SequenceTable({
  results,
  handleDomainIdChange,
  canSelect = false,
}) {
  return (
    results.length > 0 && (
      <article className="space-y-4">
        <h4 className="font-semibold text-2xl">Sequence Alignment Results</h4>
        <table className="w-full text-center">
          <thead>
            <tr className="bg-gray-200">
              <th className="border-2 border-gray-400 py-2 px-1">
                Alignment Hit ID
              </th>
              <th className="border-2 border-gray-400 py-2 px-1">
                Alignment Length
              </th>
              <th className="border-2 border-gray-400 py-2 px-1">
                Alignment Title
              </th>
              <th className="border-2 border-gray-400 py-2 px-1">HSP Expect</th>
              <th className="border-2 border-gray-400 py-2 px-1">Identity</th>
              <th className="border-2 border-gray-400 py-2 px-1">
                Query Coverage
              </th>
            </tr>
          </thead>
          <tbody>
            {results.map((result) => (
              <SequenceResult
                key={result.alignment_hit_id}
                result={result}
                canSelect={canSelect}
                handleDomainIdChange={
                  canSelect ? handleDomainIdChange : () => {}
                }
              />
            ))}
          </tbody>
        </table>
      </article>
    )
  );
}
