export default function NameInput({ name, handleChange, nameError }) {
  return (
    <>
      <input
        type="text"
        placeholder="Portfolio Name"
        value={name}
        onChange={(e) => handleChange(e.target.value)}
        className={`w-full p-2 border-2 border-gray-300 rounded-lg ${
          nameError && "border-2 border-red-500"
        }`}
      />
      {nameError && <p className="text-red-500 text-sm">{nameError}</p>}
    </>
  );
}
