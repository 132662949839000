import axios from "axios";
import toast from "react-hot-toast";
import { API_URL } from "../api";

export async function updateNotes(id, notes, navigate, setIsLoading) {
  toast.dismiss();
  setIsLoading(true);
  try {
    const response = await axios.post(
      `${API_URL}/users/update_notes?project_id=${id}&notes=${notes}`
    );
    if (response.status !== 200) throw response;
    navigate(0, { replace: true });
  } catch (error) {
    error?.data?.detail
      ? toast.error(`Error: ${error.data.detail}`)
      : toast.error(
          "An error occurred while processing your request, please try again."
        );
  } finally {
    setIsLoading(false);
  }
}

async function handleDeleteProject(id, navigate) {
  toast.dismiss();
  try {
    const response = await axios.post(
      `${API_URL}/users/delete_project?project_id=${id}`
    );
    if (response.status !== 200) throw response;
    navigate("/projects");
  } catch (error) {
    error?.data?.detail
      ? toast.error(`Error: ${error.data.detail}`)
      : toast.error(
          "An error occurred while processing your request, please try again."
        );
  } finally {
    document.body.style.overflow = "auto";
  }
}

export function showDeleteModal(id, navigate) {
  toast.dismiss();
  document.body.style.overflow = "hidden";
  toast.loading(
    <div className="flex flex-col space-y-4 text-center">
      <span className="text-lg">
        Are you sure you want to <strong>delete this project</strong>?
      </span>
      <span className="text-balance text-sm">
        This action <strong>can't be undone</strong>
      </span>
      <div className="w-full flex justify-evenly">
        <button
          onClick={() => handleDeleteProject(id, navigate)}
          className="border-2 hover:bg-red-600 p-2 w-1/3 duration-200 hover:text-white rounded-lg">
          Yes
        </button>
        <button
          onClick={() => {
            toast.dismiss();
            document.body.style.overflow = "auto";
          }}
          className="bg-blue-500 hover:bg-blue-600 p-2 w-1/3 duration-200 text-white rounded-lg">
          No
        </button>
      </div>
    </div>,
    { icon: null }
  );
}
