import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { API_URL } from "../utils/api";
import { toast } from "react-hot-toast";
import axios from "axios";

export default function RegisterClient() {
  const [email, setEmail] = useState("");
  const [notes, setNotes] = useState("");
  const [emailError, setEmailError] = useState("");
  const [clicked, setClicked] = useState(false);
  const navigate = useNavigate();

  function validateData() {
    let isValid = true;
    setEmailError("");
    if (email.trim() === "") {
      setEmailError("Email must not be empty");
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setEmailError("Please validate email format");
      isValid = false;
    }
    return isValid;
  }

  const handleSubmit = async (e) => {
    toast.dismiss();
    e.preventDefault();
    if (validateData()) {
      setClicked(true);
      try {
        await toast.promise(
          new Promise((resolve, reject) => {
            setTimeout(() => {
              axios
                .post(
                  `${API_URL}/users/register_client`,
                  { email, notes },
                  {
                    timeout: 5000,
                  }
                )
                .then(resolve)
                .catch(reject);
            }, 2000);
          }),
          {
            pending: "Signing you up...",
            success: `A verification email has been sent to ${email}`,
          }
        );
        setEmail("");
        setNotes("");
        navigate("/login");
      } catch ({ response }) {
        const errorMessage =
          response && response.data && response.data.detail
            ? response.data.detail
            : "An unexpected error has occurred";
        setTimeout(() => toast.error(errorMessage), 500);
      }
      setClicked(false);
    }
  };

  return (
    <section className="min-h-screen bg-gray-100 flex items-center justify-center px-4">
      <form
        onSubmit={handleSubmit}
        className="bg-white rounded-xl shadow-2xl py-6 px-4 space-y-6 w-full md:max-w-2xl">
        <h1 className="text-black text-center font-bold text-3xl">Sign Up</h1>
        <p className="italic bg-gray-200 text-balance p-2 rounded-md shadow-sm border-2 border-gray-300 text-center">
          <span className="font-bold tracking-wide">Note:</span> After entering
          your email address, you will recieve a confirmation code on that same
          email address to verify your identity.
        </p>
        <div className="space-y-2">
          <label className="font-semibold" htmlFor="email">
            * Email Address
          </label>
          <input
            name="email"
            id="email"
            type="text"
            className={`form-control ${emailError ? "is-invalid" : ""}`}
            placeholder="Type your email address here..."
            value={email}
            onChange={({ target }) => {
              setEmail(target.value.trim());
              setEmailError("");
            }}
          />
          {emailError && <div className="invalid-feedback">{emailError}</div>}
        </div>
        <div className="space-y-2">
          <label className="font-semibold" htmlFor="notes">
            Notes
          </label>
          <textarea
            name="notes"
            id="notes"
            className="form-control"
            rows="3"
            placeholder="Notes that may need to be added..."
            value={notes}
            onChange={({ target }) => setNotes(target.value)}></textarea>
        </div>
        <div className="flex flex-col justify-center items-center space-y-4">
          <button
            type="submit"
            className="bg-blue-500 text-white font-semibold py-2 px-6 rounded hover:bg-blue-600 hover:-translate-y-1 duration-200 w-full sm:w-1/3 md:py-3 md:px-8 md:rounded-lg"
            disabled={clicked}>
            Register
          </button>
          <Link
            className="text-sm group text-black hover:scale-105 duration-200"
            to="/login">
            Already have an account?{" "}
            <span className="text-blue-800 group-hover:underline">Log In</span>
          </Link>
        </div>
        <p className="text-xs text-center">* Required field</p>
      </form>
    </section>
  );
}
