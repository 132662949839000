import { useEffect, useState } from "react";

export default function useLoadFile(stageRef) {
  const [file, setFile] = useState(null);
  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const string = reader.result;
        const blob = new Blob([string], { type: "text/plain" });

        // Remove previous components before loading new file
        stageRef.current.removeAllComponents();

        stageRef.current.loadFile(blob, { ext: "pdb" }).then((component) => {
          component.addRepresentation("cartoon");
          component.autoView();
        });
      };
      reader.readAsText(file);
    }
  }, [file, stageRef]);

  return setFile;
}
