import MoleculeViewer from "./module4/MoleculeViewer";

export default function Module4() {
  return (
    <section className="md:w-2/3 flex flex-col space-y-6 w-full border px-4 py-8 rounded-lg overflow-scroll">
      <h3 className="text-center text-balance font-bold text-3xl">
        Module 4 Molecule Viewer
      </h3>
      <MoleculeViewer />
    </section>
  );
}
