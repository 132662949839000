import { useState } from "react";
import { updateNotes } from "../../utils/project-info/helpers";
import { useNavigate } from "react-router-dom";
import SubmitButton from "./ui/SubmitButton";
import CancelButton from "./ui/CancelButton";

export default function ProjectNotesForm({
  projectNotes,
  setProjectNotes,
  setCanEditNotes,
  projectDetails,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    updateNotes(
      projectDetails?.project_id,
      projectNotes,
      navigate,
      setIsLoading
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={projectNotes}
        onChange={({ target }) => setProjectNotes(target.value)}
        className="border-b-2 border-gray-500 px-2 py-1 outline-none w-full"
      />
      <div className="flex w-1/3 space-x-2 justify-center items-center">
        <SubmitButton isLoading={isLoading} />
        <CancelButton
          isLoading={isLoading}
          handleClick={() => setCanEditNotes(false)}
        />
      </div>
    </form>
  );
}
