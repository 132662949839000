export default function Input({
  identifier,
  placeholder,
  value,
  setValue,
  setShowOptions,
  readOnly = false,
  disabled = false,
  double = true,
}) {
  return (
    <input
      type="text"
      readOnly={readOnly}
      disabled={disabled}
      name={identifier}
      id={identifier}
      className={`${
        double ? "rounded-l-lg" : "rounded-lg"
      } bg-gray-100 p-2 w-full duration-100 focus:border-gray-400 focus:border outline-none ${
        disabled && "cursor-not-allowed"
      }`}
      placeholder={placeholder}
      value={value}
      onChange={({ target }) => setValue({ value: target.value, error: "" })}
      onFocus={() => setShowOptions(true)}
      onBlur={() => setShowOptions(false)}
      autoComplete="off"
    />
  );
}
