import { useState } from "react";
import Modal from "../components/Modal";
import axios from "axios";
import { API_URL } from "../utils/api";
import { agregarCero } from "../utils/formatters";
import { useNavigate } from "react-router-dom";
import useRetrieveProjects from "../hooks/useRetrieveProjects";
import toast from "react-hot-toast";

export default function Projects() {
  const [isOpenAddNewProject, setIsOpenAddNewProject] = useState(false);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [notes, setNotes] = useState("");
  const [notesError, setNotesError] = useState("");

  const navigate = useNavigate();

  const { isLoading, projects, setIsLoading, setProjects } =
    useRetrieveProjects();

  function validateData() {
    let isValid = true;
    setNameError("");
    setNotesError("");
    if (!name) {
      isValid = false;
      setNameError("Project name is required");
    }
    if (!notes) {
      isValid = false;
      setNotesError("Project notes are required");
    }
    return isValid;
  }

  async function handleSubmit(e) {
    toast.dismiss();
    e.preventDefault();
    setIsLoading(true);
    if (validateData()) {
      try {
        const createProjectResponse = await axios.post(
          `${API_URL}/users/create_project`,
          { name, notes }
        );

        if (createProjectResponse.status !== 201) throw createProjectResponse;

        setIsOpenAddNewProject(false);
        setName("");
        setNotes("");

        const getProjectsResponse = await axios.post(
          `${API_URL}/users/get_user_projects`
        );

        if (getProjectsResponse.status !== 200) throw getProjectsResponse;

        setProjects(getProjectsResponse.data);
      } catch (error) {
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error(
              "An error occurred while processing your request, please try again."
            );
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      {isOpenAddNewProject && (
        <Modal
          handleClose={() => {
            setIsOpenAddNewProject(false);
            setName("");
            setNotes("");
            setNameError("");
            setNotesError("");
          }}>
          <div className="w-full text-center md:flex md:flex-col md:items-center md:justify-center mt-12">
            <h2 className="text-3xl font-bold">Create New Project</h2>
            <br />
            <form
              onSubmit={handleSubmit}
              className="flex flex-col items-center w-full space-y-6">
              <div className="w-full flex flex-col items-start">
                <label htmlFor="projectName" className="px-2 text-xl">
                  Project Name *
                </label>
                <input
                  name="projectName"
                  id="projectName"
                  type="text"
                  placeholder="(max: 50 characters)"
                  className="w-full p-2 text-black bg-gray-100 rounded-lg"
                  onChange={(e) => {
                    e.target.value.length > 50
                      ? setNameError("Character limit reached")
                      : setNameError("");
                    setName((prev) =>
                      e.target.value.length > 50 ? prev : e.target.value
                    );
                  }}
                  value={name}
                />
                {nameError && <p className="text-red-500">{nameError}</p>}
              </div>
              <div className="w-full flex flex-col items-start">
                <label htmlFor="projectNotes" className="px-2 text-xl">
                  Project Notes *
                </label>
                <textarea
                  name="projectNotes"
                  id="projectNotes"
                  placeholder="(max: 300 characters)"
                  className="w-full p-2 text-black bg-gray-100 rounded-lg"
                  onChange={(e) => {
                    e.target.value.length > 300
                      ? setNotesError("Character limit reached")
                      : setNotesError("");
                    setNotes((prev) =>
                      e.target.value.length > 300 ? prev : e.target.value
                    );
                  }}
                  value={notes}
                />
                {notesError && <p className="text-red-500">{notesError}</p>}
              </div>

              <button
                className={`bg-blue-500 text-white py-2 px-4 rounded-lg flex mx-auto justify-center w-1/3 hover:bg-blue-600 duration-200 ${
                  isLoading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isLoading}>
                {isLoading ? (
                  <img
                    src="https://storage.googleapis.com/gpp4-bucket/rsc/rsc/loader.svg"
                    alt="loader"
                    className="h-6 w-6"
                  />
                ) : (
                  "Create Project"
                )}
              </button>
            </form>
            <br />
            <p>* Required field</p>
          </div>
        </Modal>
      )}
      <main
        className={`overflow-scroll mx-auto ${
          isLoading
            ? "bg-gray-400 w-full flex justify-center items-center min-h-[59dvh]"
            : "flex flex-col py-6 space-y-6 px-8 min-h-[59dvh] justify-center"
        }
        ${!isLoading && "max-w-5xl "}
        `}>
        {isLoading && (
          <img
            src="https://storage.googleapis.com/gpp4-bucket/rsc/rsc/loader.svg"
            alt="loading"
          />
        )}
        {!isLoading && projects?.length > 0 && (
          <div className="flex flex-col justify-between space-y-6 md:space-y-0 md:flex-row">
            <h2 className="text-center text-3xl font-bold">My Projects</h2>
          </div>
        )}
        {!isLoading && projects?.length === 0 && (
          <h2 className="text-center text-3xl font-bold">My Projects</h2>
        )}
        {!isLoading && (
          <section className="space-y-6 lg:space-y-10 flex flex-col overflow-scroll">
            {projects?.length === 0 && (
              <>
                <h3 className="text-center text-xl font-semibold">
                  You don't have any projects yet
                </h3>
                <article className="bg-gray-100 py-4 rounded-xl flex flex-col items-center w-full space-y-6 px-10 xl:w-1/2 mx-auto">
                  <p className="text-center text-lg">
                    Start by creating a new project
                  </p>
                  <button
                    className="py-2 px-6 bg-blue-500 text-white rounded-xl hover:bg-blue-600 hover:-translate-y-1 duration-200"
                    onClick={() => setIsOpenAddNewProject((prev) => !prev)}>
                    Create New Project
                  </button>
                </article>
              </>
            )}
            {projects?.length > 0 && (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                <div
                  onClick={() => setIsOpenAddNewProject((prev) => !prev)}
                  className="rounded-lg shadow-md p-4 hover:shadow-lg duration-300 cursor-pointer space-y-4 bg-blue-500 text-white hover:bg-blue-600 flex flex-col justify-center items-center text-center text-xl">
                  <p className="text-[7rem]">+</p>
                  <p className="text-lg">Create New Project</p>
                </div>
                {projects?.map((project) => (
                  <div
                    key={project?.project_id}
                    className="bg-white border border-gray-200 rounded-lg shadow-md p-4 hover:shadow-2xl transition-shadow duration-300 cursor-pointer flex flex-col justify-between space-y-4"
                    onClick={() => {
                      localStorage.setItem("project", JSON.stringify(project));
                      navigate(
                        `/project-details?project=${project?.project_id}`
                      );
                    }}>
                    <h2 className="text-xl font-semibold text-blue-500 hover:text-blue-600">
                      {project?.name}
                    </h2>
                    <p className="text-gray-700 text-sm max-h-16 overflow-hidden text-ellipsis">
                      {project?.notes}
                    </p>
                    <p className="text-gray-500 text-sm">
                      Created on:{" "}
                      {agregarCero(new Date(project?.createdAt).getDate()) +
                        "/" +
                        agregarCero(
                          new Date(project?.createdAt).getMonth() + 1
                        ) +
                        "/" +
                        new Date(project?.createdAt).getFullYear()}
                    </p>
                    <p className="text-gray-500 text-sm">
                      Last updated:{" "}
                      {project?.updatedAt
                        ? agregarCero(new Date(project?.updatedAt).getHours()) +
                          ":" +
                          agregarCero(
                            new Date(project?.updatedAt).getMinutes()
                          ) +
                          " " +
                          (agregarCero(new Date(project?.updatedAt).getDate()) +
                            "/" +
                            agregarCero(
                              new Date(project?.updatedAt).getMonth() + 1
                            ) +
                            "/" +
                            new Date(project?.updatedAt).getFullYear())
                        : "Never updated"}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </section>
        )}
      </main>
    </>
  );
}
