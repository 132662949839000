import React from "react";
import Modal from "./Modal";

export default function InputInstructions({ handleClose }) {
  return (
    <Modal handleClose={handleClose}>
      <h2 className="text-2xl font-bold">
        How to use our Gene Paradox Platform?
      </h2>
      <br />
      <p>
        Experience the ease of our Gene Paradox Platform by simply clicking the
        button below. This will take you to a user-friendly interface where you
        can input essential details to enhance your research.
        <br />
        <br />
        Provide information such as the name of your gene of interest, the
        organism it originates from, and any alternative organism(s) you're
        considering for efficient protein production.
        <br />
        <br />
        Once your details are submitted, our robust software will swiftly
        analyze the data, delivering comprehensive results directly to your
        registered email inbox. Elevate your research with the power of
        streamlined bioinformatics at your fingertips.
      </p>
    </Modal>
  );
}
