// import { useState } from "react";
// import { PDF_API_URL } from "../utils/api";
import { useSearchParams } from "react-router-dom";
import Portfolios from "./module5/Portfolios";

export default function Module5() {
  const [searchParams] = useSearchParams();
  const portfolioId = searchParams.get("portfolio");

  return (
    <section className="md:w-2/3 w-full border px-4 py-8 rounded-lg overflow-scroll">
      {!portfolioId && <Portfolios />}
    </section>
  );
}
