import { useEffect } from "react";
import Axios from "axios";
import { API_URL } from "../utils/api";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

export default function ValidateEmail() {
  const { code } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const validateEmail = async () => {
      try {
        const response = await Axios.post(
          `${API_URL}/users/complete_register/${code}`
        );
        if (response.status !== 200) throw response;

        sessionStorage.setItem("user", JSON.stringify(response.data.user_info));
        navigate("/confirm");
      } catch (error) {
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error(
              "An error occurred while validating email, please try again."
            );
        navigate("/login");
      }
    };

    validateEmail();
  }, [code, navigate]);
}
