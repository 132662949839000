import axios from "axios";
import { setUser, deleteUser, getUser } from "./user";

function handleLogout() {
  deleteUser();
  localStorage.setItem(
    "invalid_credentials",
    "Your session has expired, please login again"
  );
  window.location.href = "/";
}

export const API_URL = "https://api.geneparadox.com";

export const PDF_API_URL = "http://34.173.5.88:81/pdf_docs";

export function initAxiosInterceptors() {
  axios.interceptors.request.use(async (config) => {
    const source = axios.CancelToken.source();

    if (!config.cancelToken) config.cancelToken = source.token;

    const user = getUser();

    if (user?.access_token && user?.refresh_token && user?.user)
      config.headers.Authorization = `Bearer ${user.access_token}`;

    if (!config.timeout) config.timeout = 30000;

    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response && error.response.status === 401) {
        try {
          const temp_user = getUser();
          const response = await fetch(`${API_URL}/users/refresh_token`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(temp_user.refresh_token),
          });

          if (!response.ok) throw error;

          const data = await response.json();

          setUser({
            ...temp_user,
            access_token: data.access_token,
            refresh_token: data.refresh_token,
          });

          window.location.reload();
        } catch (refresh_error) {
          if (refresh_error.response && refresh_error.response.status === 401) {
            handleLogout();
          } else {
            return Promise.reject(refresh_error);
          }
        }
      } else {
        return Promise.reject(error);
      }
    }
  );
}
