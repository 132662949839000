import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import { PDF_API_URL } from "../../../utils/api";

import CancelButton from "./CancelButton";
import SubmitButton from "./SubmitButton";
import NameInput from "./NameInput";

export default function CreatePortfolioForm({ setIsCreating }) {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("project");

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    toast.dismiss();
    e.preventDefault();
    setIsLoading(true);
    if (!name.trim()) {
      setNameError("Portfolio name is required.");
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.post(
        `${PDF_API_URL}/create_portfolio?portfolio_name=${encodeURIComponent(
          name
        )}&project_id=${projectId}`
      );
      if (response.status !== 201) throw response;
      setIsCreating(false);
      window.location.reload();
    } catch (error) {
      error?.data?.detail
        ? toast.error(`Error: ${error.data.detail}`)
        : toast.error(
            "An error occurred while creating portfolio. Please try again."
          );
    }
    setIsLoading(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col justify-center items-center rounded-lg text-center p-4 space-y-4 border-2 border-gray-200">
      <h3 className="text-2xl font-semibold">Create New Portfolio</h3>
      <div className="flex flex-col space-y-2 items-center w-full">
        <NameInput
          name={name}
          nameError={nameError}
          handleChange={(val) => {
            setName(val);
            setNameError("");
          }}
        />
      </div>

      <div className="flex justify-between space-x-4 w-full">
        <CancelButton handleCancel={() => setIsCreating(false)} />
        <SubmitButton isLoading={isLoading} />
      </div>
    </form>
  );
}
