import { useState } from "react";
import useRetrieveProjectInfo from "../hooks/useRetrieveProjectInfo";
import CreatedAt from "./project_details/ui/CreatedAt";
import ProjectNotesForm from "./project_details/ProjectNotesForm";
import ProjectNotesDisplay from "./project_details/ProjectNotesDisplay";
import UpdatedAt from "./project_details/ui/UpdatedAt";
import Name from "./project_details/ui/Name";
import DeleteProject from "./project_details/ui/DeleteProject";
import Divider from "./ui/Divider";

export default function Project({
  isLoadingInformation,
  setIsLoadingInformation,
}) {
  const { projectDetails, projectNotes, setProjectNotes } =
    useRetrieveProjectInfo(setIsLoadingInformation);

  const [canEditNotes, setCanEditNotes] = useState(false);
  // TODO: change how notes input is set
  return (
    <section className="md:w-2/3 flex flex-col space-y-6 w-full">
      <main className="space-y-6">
        <Name
          projectDetails={projectDetails}
          isLoadingInformation={isLoadingInformation}
        />
        {canEditNotes ? (
          <ProjectNotesForm
            projectNotes={projectNotes}
            setProjectNotes={setProjectNotes}
            setCanEditNotes={setCanEditNotes}
            projectDetails={projectDetails}
          />
        ) : (
          <ProjectNotesDisplay
            projectDetails={projectDetails}
            isLoadingInformation={isLoadingInformation}
            setCanEditNotes={setCanEditNotes}
          />
        )}
        <CreatedAt
          projectDetails={projectDetails}
          isLoadingInformation={isLoadingInformation}
        />
        <UpdatedAt
          projectDetails={projectDetails}
          isLoadingInformation={isLoadingInformation}
        />
      </main>
      <Divider />
      <DeleteProject projectDetails={projectDetails} />
    </section>
  );
}
