import { useState } from "react";

export default function PaginatedTable({ items }) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="space-y-4 w-full">
      <table className="w-full border-collapse">
        <thead>
          <tr>
            <th className="border-b-2 border-gray-300 p-2 bg-gray-100 text-left">
              Result
            </th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item, index) => (
            <tr key={index} className="even:bg-gray-50 hover:bg-gray-200">
              <td className="border-b border-gray-200 p-2">{item}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex justify-between items-center mt-4">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="p-2 border rounded-md bg-gray-200 disabled:opacity-50 w-1/4 enabled:hover:bg-gray-400 duration-200 disabled:cursor-not-allowed">
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="p-2 border rounded-md bg-gray-200 disabled:opacity-50 w-1/4 enabled:hover:bg-gray-400 duration-200 disabled:cursor-not-allowed">
          Next
        </button>
      </div>
    </div>
  );
}
