import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { PDF_API_URL, API_URL } from "../utils/api";
import toast from "react-hot-toast";
import { useSearchParams, useNavigate } from "react-router-dom";

const handleError = (error) => {
  error?.data?.detail
    ? toast.error(`Error: ${error.data.detail}`)
    : toast.error(
        "An error occurred while retrieving portfolios, please try again."
      );
};

export default function useRetrievePortfolios() {
  const [portfolios, setPortfolios] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("project");
  const navigate = useNavigate();

  const fetchPortfolios = useCallback(async () => {
    try {
      const response = await axios.get(
        `${PDF_API_URL}/get_portfolios?project_id=${id}`
      );
      if (response.status !== 200) throw response;
      setPortfolios(response.data);
    } catch (error) {
      handleError(error);
    }
  }, [id]);

  const getProject = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${API_URL}/users/get_proyect_info?project_id=${id}`
      );
      if (response.status !== 200) throw response;
      else if (response.data?.error === "Project does not belong to user.") {
        response.data.detail = "This project does not exist.";
        navigate("/projects");
        throw response;
      } else await fetchPortfolios();
    } catch (error) {
      handleError(error);
      navigate(`/project-details?project=${id}`);
    } finally {
      setIsLoading(false);
    }
  }, [fetchPortfolios, id, navigate]);

  useEffect(() => {
    if (!id) {
      navigate("/projects");
      return;
    }
    getProject();
  }, [getProject, id, navigate]);

  return { portfolios, isLoading };
}
