import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { API_URL } from "../utils/api";
import { getUser } from "../utils/user";

const useAutocomplete = (value, url) => {
  const [options, setOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (value === "" || value.trim().length < 3) {
      return;
    }
    toast.dismiss();
    const controller = new AbortController();
    const user = getUser();
    const getOptions = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(`${API_URL}${url}?q=${value}`, {
          signal: controller.signal,
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        });

        if (!res.ok) throw new Error("The data could not be retrieved");

        const data = await res.json();
        setOptions(data || []);
      } catch (err) {
        if (err.name !== "AbortError") {
          console.error(err.message);
          toast.error("An unexpected error has occurred, please try again");
        }
      }
      setIsLoading(false);
    };
    getOptions();

    return () => {
      controller.abort();
    };
  }, [value, url]);

  return {
    options,
    showOptions,
    setShowOptions,
    isLoading,
  };
};

export default useAutocomplete;
