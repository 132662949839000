const BioprocessReport = ({ report }) => {
  return (
    <div>
      {report.map((pathwayGroup, index) => (
        <div key={index} className="space-y-4">
          {pathwayGroup.map((pathway, pathwayIndex) => (
            <div key={pathwayIndex} className="border p-4 rounded-md">
              <h2 className="text-xl font-bold">{pathway.pathway_name}</h2>
              <p className="text-sm text-gray-600">{pathway.pathway_code}</p>
              {pathway.matched_modules.map((module, moduleIndex) => (
                <div key={moduleIndex} className="border-t mt-4 pt-4">
                  <h3 className="text-lg font-semibold">{module.name}</h3>
                  <p className="text-sm text-gray-600">{module.module_code}</p>
                  <Pathways pathways={module.pathways} />
                  <Orthology orthology={module.founded_ways.orthology} />
                  <Reactions reactions={module.founded_ways.reactions} />
                  <Compounds compounds={module.founded_ways.compounds} />
                  <DfsProcess dfsProcess={module.founded_ways.dfs_process} />
                  <FluxDeviations fluxDeviations={module.flux_deviations} />
                </div>
              ))}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const Pathways = ({ pathways }) => (
  <div className="mt-2">
    <h4 className="text-md font-semibold">Pathways</h4>
    <ul className="list-disc list-inside">
      {pathways.map((pathway, index) => (
        <li key={index}>
          {pathway.description} ({pathway.code})
        </li>
      ))}
    </ul>
  </div>
);

const Orthology = ({ orthology }) => (
  <div className="mt-2">
    <h4 className="text-md font-semibold">Orthology</h4>
    <ul className="list-disc list-inside">
      {orthology.map((ortho, index) => (
        <li key={index}>
          {ortho.name}: {ortho.enzyme_name} (EC: {ortho.ec_numbers}, Reactions:{" "}
          {ortho.reactions})
        </li>
      ))}
    </ul>
  </div>
);

const Reactions = ({ reactions }) => (
  <div className="mt-2">
    <h4 className="text-md font-semibold">Reactions</h4>
    <ul className="list-disc list-inside">
      {reactions.map((reaction, index) => (
        <li key={index}>
          {reaction.description} ({reaction.code})
        </li>
      ))}
    </ul>
  </div>
);

const Compounds = ({ compounds }) => (
  <div className="mt-2">
    <h4 className="text-md font-semibold">Compounds</h4>
    <ul className="list-disc list-inside">
      {compounds.map((compound, index) => (
        <li key={index}>
          {compound.name} ({compound.code})
        </li>
      ))}
    </ul>
  </div>
);

const DfsProcess = ({ dfsProcess }) => (
  <div className="mt-2">
    <h4 className="text-md font-semibold">DFS Process</h4>
    <ul className="list-disc list-inside">
      {dfsProcess.map((process, index) => (
        <li key={index}>
          {process.from} to {process.to.join(", ")}
        </li>
      ))}
    </ul>
  </div>
);

const FluxDeviations = ({ fluxDeviations }) => (
  <div className="mt-2">
    <h4 className="text-md font-semibold">Flux Deviations</h4>
    <ul className="list-disc list-inside">
      {fluxDeviations.map((deviation, index) => (
        <li key={index}>
          {deviation.description} ({deviation.code})
        </li>
      ))}
    </ul>
  </div>
);

export default BioprocessReport;
