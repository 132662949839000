import Skeleton from "../../ui/Skeleton";

export default function UpdatedAt({ isLoadingInformation, projectDetails }) {
  return isLoadingInformation ? (
    <div className="flex items-center space-x-2">
      <p className="text-lg">Last updated:</p>
      <Skeleton className="h-4 w-1/4 bg-slate-300" />
    </div>
  ) : (
    <p className="text-lg">
      Last updated:{" "}
      {projectDetails?.updatedAt || "Project has not been updated"}
    </p>
  );
}
