import { useRef, useEffect } from "react";
import * as NGL from "ngl";

export default function useInitializeStage() {
  const stageRef = useRef(null);

  useEffect(() => {
    if (!stageRef.current) {
      const stage = new NGL.Stage("viewport", { backgroundColor: "white" });
      stageRef.current = stage;
    }
  }, []);

  return stageRef;
}
