import React, { useState } from "react";
import { toast } from "react-hot-toast";
import axios from "axios";
import InputInstructions from "../components/InputInstructions";
import InputThanks from "../components/InputThanks";

const initialState = {
  question1: "",
  question2: "",
  question3: "",
  errors: {
    question1: "",
    question2: "",
    question3: "",
  },
};

export default function GeneInputForm({ userInformation }) {
  const [isClicked, setIsClicked] = useState(false);
  const [isOpenThanks, setIsOpenThanks] = useState(false);
  const [hasReadInstructions, setHasReadInstructions] = useState(false);
  const [formState, setFormState] = useState(initialState);

  function validateInput() {
    let isValid = true;
    const newErrors = { ...initialState.errors };

    for (const question in formState) {
      if (
        typeof formState[question] === "string" &&
        formState[question].trim() === ""
      ) {
        newErrors[question] = `${
          question.charAt(0).toUpperCase() +
          question.slice(1, 8) +
          " " +
          question.slice(8, 9)
        } cannot be empty`;
        isValid = false;
      }
    }

    setFormState((prev) => ({ ...prev, errors: newErrors }));
    return isValid;
  }

  async function handleSubmit(e) {
    toast.dismiss();
    e.preventDefault();

    if (validateInput()) {
      setIsClicked(true);
      try {
        await toast.promise(
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const format = (date, locale, options) =>
                new Intl.DateTimeFormat(locale, options).format(date);
              const now = new Date();
              const data = {
                Name: userInformation.name,
                Email: userInformation.email,
                "Question 1": formState.question1,
                "Question 2": formState.question2,
                "Question 3": formState.question3,
                Date: format(now, "en", { dateStyle: "long" }),
              };

              axios
                .post(
                  "https://sheet.best/api/sheets/ebbe0a47-8372-4cd2-89d9-d6b5a4819aa1",
                  data,
                  { timeout: 5000 }
                )
                .then(resolve)
                .catch(reject);
            }, 2000);
          }),
          {
            pending: "Submitting Information...",
            success: "Information sent correcty",
          }
        );
        setFormState(initialState);
        setIsOpenThanks(true);
      } catch (err) {
        const errorMessage =
          err?.response?.data?.detail || "An unexpected error has occurred";
        setTimeout(() => toast.error(errorMessage), 5000);
      }
      setIsClicked(false);
    }
  }

  return (
    <>
      {!hasReadInstructions && (
        <InputInstructions handleClose={() => setHasReadInstructions(true)} />
      )}
      {isOpenThanks && (
        <InputThanks
          handleClose={() => setIsOpenThanks(false)}
          path="/gene_input_form"
        />
      )}
      <section className="w-full md:flex md:justify-center min-h-[59dvh]">
        <form className="my-4 p-6 space-y-8 md:w-1/2" onSubmit={handleSubmit}>
          <h2 className="text-4xl font-bold text-center">
            Gene Input Information
          </h2>
          <p className="bg-gray-200 rounded-xl px-4 py-2 italic shadow-xl">
            Unlock the full potential of protein production with the Gene
            Paradox Platform. Our industry-leading platform combines the power
            of BLAST and InterPro to identify the best microorganism hosts for
            your specific protein, maximizing yields, reducing costs, and
            ensuring top quality.
          </p>
          <article>
            <label className="form-label font-semibold" htmlFor="question1">
              Gene of interest
            </label>
            <input
              type="text"
              id="question1"
              className={`form-control ${
                formState.errors.question1 ? "is-invalid" : ""
              }`}
              placeholder="Example: INS insuline"
              onChange={({ target }) =>
                setFormState((prev) => ({ ...prev, question1: target.value }))
              }
              value={formState.question1}
            />
            {formState.errors.question1 && (
              <div className="invalid-feedback">
                {formState.errors.question1}
              </div>
            )}
          </article>

          <article>
            <label className="form-label font-semibold" htmlFor="question2">
              Organism of origin of the gene of interest
            </label>
            <input
              type="text"
              id="question2"
              className={`form-control ${
                formState.errors.question2 ? "is-invalid" : ""
              }`}
              placeholder="Example: Homo sapiens"
              onChange={({ target }) =>
                setFormState((prev) => ({ ...prev, question2: target.value }))
              }
              value={formState.question2}
            />
            {formState.errors.question2 && (
              <div className="invalid-feedback">
                {formState.errors.question2}
              </div>
            )}
          </article>

          <article>
            <label className="form-label font-semibold" htmlFor="question3">
              Alternative organisms with high potential for efficient protein
              production
            </label>
            <textarea
              id="question3"
              className={`form-control ${
                formState.errors.question3 ? "is-invalid" : ""
              }`}
              placeholder="Example: Escherichia coli"
              onChange={({ target }) =>
                setFormState((prev) => ({ ...prev, question3: target.value }))
              }
              value={formState.question3}
            />
            {formState.errors.question3 && (
              <div className="invalid-feedback">
                {formState.errors.question3}
              </div>
            )}
          </article>

          <article className="flex justify-center w-full">
            <button
              disabled={isClicked}
              type="submit"
              className="shadow-xl px-8 py-3 text-white bg-blue-500  rounded-xl hover:opacity-70 hover:-translate-y-1 duration-200">
              Submit
            </button>
          </article>
        </form>
      </section>
    </>
  );
}
