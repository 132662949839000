import { useState, useEffect } from "react";

import axios from "axios";
import { API_URL } from "../../utils/api";
import toast from "react-hot-toast";

import FastaDisplay from "./ui/FastaDisplay";
import SearchForm from "./ui/SearchForm";
import SearchInput from "./ui/SearchInput";
import SearchButton from "./ui/SearchButton";

export default function ProteinAlignment({
  cutSequence,
  setProteinAlignmentResults,
}) {
  const [protein, setProtein] = useState({
    value: "",
    error: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [blastId, setBlastId] = useState("");

  useEffect(() => {
    if (!blastId || finished) return;
    if (!isLoading) {
      setIsLoading(true);
    }
    const interval = setInterval(() => {
      try {
        axios
          .post(`${API_URL}/seq_alignment/blast/status?rid=${blastId}`)
          .then((response) => {
            if (response.data && response.data === "FINISHED") {
              clearInterval(interval);
              setIsLoading(false);
              toast.dismiss();
              try {
                setIsLoading(true);
                axios
                  .post(`${API_URL}/seq_alignment/blast/get?rid=${blastId}`)
                  .then((response) => {
                    if (response.data) {
                      setIsLoading(false);
                      toast.dismiss();
                      setProteinAlignmentResults(response.data);
                      clearInterval(interval);
                      setFinished(true);
                    } else {
                      setIsLoading(false);
                      toast.dismiss();
                      toast.error(
                        "An unexpected error has occurred, please try again"
                      );
                      clearInterval(interval);
                      setFinished(true);
                    }
                  });
              } catch (e) {
                console.error(e);
                setIsLoading(false);
                toast.dismiss();
                toast.error(
                  "An unexpected error has occurred, please try again"
                );
              }
            }
          });
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        setBlastId("");
        toast.dismiss();
        toast.error("An unexpected error has occurred, please try again");
        clearInterval(interval);
      }
    }, 7500);
    return () => {
      clearInterval(interval);
    };
  }, [blastId, isLoading, finished, setProteinAlignmentResults]);

  async function handleProteinAlignment(e) {
    e.preventDefault();
    toast.dismiss();
    setIsLoading(true);
    if (!protein.value.trim()) {
      setProtein({
        ...protein,
        error: "Please cut the sequence before searching for the organism",
      });
      return;
    }
    toast.loading(
      "Searching for homologus sequences\n\nThis process may take a while"
    );
    try {
      const response = await axios.post(`${API_URL}/seq_alignment/blastp/run`, {
        query: cutSequence,
        organism: protein.value.split("(")[0].trim(),
      });
      if (response.status !== 200) {
        throw new Error("The data could not be retrieved");
      }
      setBlastId(response.data);
      setFinished(false);
    } catch (e) {
      console.error(e);
      toast.error("An unexpected error has occurred, please try again");
    }
    setIsLoading(false);
  }

  return (
    <article className="space-y-4">
      <h4 className="font-semibold text-2xl">Protein Alignment</h4>

      <FastaDisplay
        identifier="cutFasta"
        handleFastaChange={() => {}}
        fastaSequence={cutSequence}
        placeholder="Once cut your sequence, the FASTA sequence will be displayed here."
      />

      <SearchForm
        labelValue={"Organism "}
        identifier={"organism"}
        handleSubmit={handleProteinAlignment}
        error={protein.error}>
        <SearchInput
          identifier={"organism"}
          placeholder={"Organism"}
          value={protein.value}
          setValue={setProtein}
          disabled={!cutSequence}
          show={true}
        />
        <SearchButton
          isLoading={isLoading}
          disabled={!cutSequence || isLoading}
        />
      </SearchForm>
    </article>
  );
}
