import { useState } from "react";
import useValidateModule from "../hooks/useValidateModule";
import GoBackButton from "../components/ui/project_details/GoBackButton";
import ModuleSelector from "../components/ModuleSelector";
import Project from "../components/Project";
import Module1 from "../components/Module1";
import Module2 from "../components/Module2";
import Module3 from "../components/Module3";
import Module4 from "../components/Module4";
import Module5 from "../components/Module5";

export default function ProjectDetails() {
  const { projectId, moduleId, navigate } = useValidateModule();
  const [isLoadingInformation, setIsLoadingInformation] = useState(false);

  return (
    <>
      <main className="w-full flex flex-col mx-auto px-8 py-6 space-y-6 min-h-[59dvh]">
        <GoBackButton navigate={navigate} />
        <div className="flex md:flex-row md:space-x-8 md:space-y-0 flex-col-reverse space-y-8 space-y-reverse w-full">
          <ModuleSelector
            projectId={projectId}
            selectedModule={moduleId}
            isLoadingInformation={isLoadingInformation}
          />
          {!moduleId && (
            <Project
              isLoadingInformation={isLoadingInformation}
              setIsLoadingInformation={setIsLoadingInformation}
            />
          )}
          {moduleId === "1" && <Module1 />}
          {moduleId === "2" && <Module2 />}
          {moduleId === "3" && <Module3 />}
          {moduleId === "4" && <Module4 />}
          {moduleId === "5" && <Module5 />}
        </div>
      </main>
    </>
  );
}
