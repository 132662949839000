import FastaLoader from "./FastaLoader";

export default function FastaDisplay({
  isLoading,
  fastaSequence,
  fastaError,
  handleFastaChange,
  identifier = "fasta",
  placeholder = "Once you do a search, the FASTA sequence will be displayed here.",
}) {
  return isLoading ? (
    <FastaLoader />
  ) : (
    <>
      <label htmlFor={identifier}>
        FASTA Sequence recovered from previous search
      </label>
      <textarea
        disabled
        className="border rounded-lg p-2 w-full duration-100 focus:border-gray-400 focus:border outline-none min-h-44 text-wrap cursor-not-allowed"
        name={identifier}
        id={identifier}
        placeholder={placeholder}
        value={fastaSequence}
        onChange={({ target }) =>
          handleFastaChange({ value: target.value, error: "" })
        }></textarea>
      {fastaError && <p className="text-red-500">{fastaError}</p>}
    </>
  );
}
