import { useState } from "react";
import GeneSearch from "./module1/GeneSearch";
import FastaSearch from "./module1/FastaSearch";
import SequenceTable from "./module1/ui/SequenceTable";
import DomainSearch from "./module1/DomainSearch";
import ProteinAlignment from "./module1/ProteinAlignment";

export default function Module1() {
  const [geneResults, setGeneResults] = useState({
    clikedIdentifier: "",
    results: [],
  });

  const [alignmentResults, setAlignmentResults] = useState({
    clikedIdentifier: "",
    results: [],
  });

  const [domainSearchId, setDomainSearchId] = useState({
    value: "",
    error: "",
  });

  const [domainFasta, setDomainFasta] = useState({
    value: "",
    error: "",
  });

  const [cutSequence, setCutSequence] = useState("");

  const [proteinAlignmentResults, setProteinAlignmentResults] = useState([]);

  return (
    <section className="md:w-2/3 flex flex-col space-y-10 w-full border px-4 py-8 rounded-lg overflow-scroll">
      <h3 className="text-center text-balance font-bold text-3xl">
        Module 1 Search of Microorganism for Protein Production
      </h3>
      <p className="bg-gray-200 text-balance p-2 rounded-md shadow-sm border-2 border-gray-300 text-center">
        The module conducts a comprehensive search, utilizing techniques such as
        BLASTX and protein domain analysis to identify if the candidate
        microorganism meet the specified criteria.
      </p>

      <GeneSearch handleGeneResultsChange={setGeneResults} />
      <FastaSearch
        geneResults={geneResults}
        handleGeneResultsChange={setGeneResults}
        handleAlignmentResultsChange={setAlignmentResults}
        // Sequence alignment is nested in FastaSearch component
      />

      {alignmentResults.results[0] === "No significant similarity found." ? (
        <p className="text-center font-semibold text-xl">
          No significant similarity found.
        </p>
      ) : (
        <SequenceTable
          results={alignmentResults.results}
          canSelect={true}
          handleDomainIdChange={setDomainSearchId}
        />
      )}

      <DomainSearch
        domainFasta={domainFasta}
        setCutSequence={setCutSequence}
        setDomainFasta={setDomainFasta}
        domainSearchId={domainSearchId}
      />

      <ProteinAlignment
        cutSequence={cutSequence}
        setProteinAlignmentResults={setProteinAlignmentResults}
      />

      {proteinAlignmentResults[0] === "No significant similarity found." ? (
        <p className="text-center font-semibold text-xl">
          No significant similarity found.
        </p>
      ) : (
        <SequenceTable
          results={proteinAlignmentResults}
          canSelect={false}
          handleDomainIdChange={() => {}}
        />
      )}
    </section>
  );
}
