export default function AutocompleteOptions({
  isLoading,
  options,
  setValue,
  show,
}) {
  return (
    <div
      className={`w-full bg-white rounded-lg shadow-lg ${!show && "absolute"}`}>
      {isLoading && (
        <div className="p-2 text-gray-500 cursor-default text-center">
          Loading...
        </div>
      )}
      {options.length > 0 &&
        !isLoading &&
        options.map((option, idx) => (
          <div
            onMouseDown={() => setValue({ value: option, error: "" })}
            key={idx}
            className="p-2 hover:bg-gray-100 cursor-pointer">
            {option}
          </div>
        ))}
      {options.length === 0 && !isLoading && (
        <div className="p-2 text-gray-500 cursor-default text-center">
          No options available
        </div>
      )}
    </div>
  );
}
