export default function CookiesNotice({ setHasAgreed }) {
  return (
    // Gene Paradox uses cookies to improve your experience in our site. To find out more, check our Privacy Policy and our Terms and Conditions.
    <div className="absolute w-screen z-50 shadow-xl">
      <div className="flex flex-col bg-[#f3f6fb] p-4 space-y-4">
        <p className="text-sm md:text-base text-center">
          Gene Paradox uses cookies to improve your experience in our site.
        </p>
        <p className="text-sm md:text-base text-center">
          To find out more, check our{" "}
          <a
            href="https://www.geneparadox.com/privacy_policy.html"
            target="_blank"
            rel="noreferrer"
            className="hover:cursor-pointer hover:font-normal hover:text-blue-600 text-blue-500">
            Privacy Policy
          </a>{" "}
          and our{" "}
          <a
            href="https://geneparadox.com/Terms_and_conditions.html"
            target="_blank"
            rel="noreferrer"
            className="hover:cursor-pointer hover:font-normal hover:text-blue-600 text-blue-500">
            Terms and Conditions
          </a>
          .
        </p>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 w-fit mx-auto"
          onClick={() => {
            localStorage.setItem("hasAgreed", true);
            setHasAgreed(true);
          }}>
          Got it!
        </button>
      </div>
    </div>
  );
}
