import tools from "../assets/tools.json";
import { useNavigate } from "react-router-dom";

export default function ToolsAside({ id }) {
  const navigate = useNavigate();
  return (
    <aside className="md:w-1/4 border-2 h-fit rounded-lg shadow-sm p-4 space-y-4">
      <h2 className="font-bold">Other tools</h2>
      <ul className="list-disc pl-4 space-y-2">
        <li
          className="text-blue-500 hover:text-blue-600 duration-200 hover:underline cursor-pointer"
          onClick={() => {
            navigate("/tools");
          }}>
          Go back to main menu
        </li>
        {tools
          .filter((tool) => tool.canUse && tool.id !== id)
          .map((tool) => (
            <li
              className="text-blue-500 hover:text-blue-600 duration-200 hover:underline cursor-pointer"
              onClick={() => {
                navigate(`${tool.link}`);
              }}
              key={tool.id}>
              {tool.name}
            </li>
          ))}
      </ul>
    </aside>
  );
}
