import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../utils/api";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function useRetrieveProjects() {
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadProjects = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(`${API_URL}/users/get_user_projects`);
        if (response.status !== 200) throw response;
        setProjects(response.data);
      } catch (error) {
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error(
              "An error occurred while retrieving projects. Please try again later."
            );
        navigate("/");
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    };
    loadProjects();
  }, [navigate]);

  return { isLoading, setIsLoading, projects, setProjects };
}
