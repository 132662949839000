import DownloadResultsButton from "./DownloadResultsButton";

export default function OrganismInformation({ results }) {
  return (
    <div className="flex flex-col space-y-2 p-3 bg-white shadow-sm rounded-lg border">
      <h4 className="text-xl font-semibold text-gray-800">
        Organism name:{" "}
        <span className="text-blue-600 font-semibold">
          {results.organism.recommended_name}
        </span>
      </h4>
      <p className="text-lg text-gray-700">
        EC Number:{" "}
        <span className="text-blue-600">{results.organism.ec_number}</span>
      </p>
      <p className="text-lg text-gray-700">
        GPP Search ID:{" "}
        <span className="text-blue-600">{results.organism.id}</span>
      </p>
      <DownloadResultsButton results={results} />
    </div>
  );
}
