import { formatDate } from "../../../utils/formatters";
import Skeleton from "../../ui/Skeleton";

export default function CreatedAt({ isLoadingInformation, projectDetails }) {
  return isLoadingInformation ? (
    <div className="flex items-center space-x-2">
      <p className="text-lg">Created:</p>
      <Skeleton className="h-4 w-1/4 bg-slate-300" />
    </div>
  ) : (
    <p className="text-lg">Created: {formatDate(projectDetails?.createdAt)}</p>
  );
}
