export default function SearchForm({
  labelValue,
  identifier,
  handleSubmit,
  children,
  error,
}) {
  return (
    <div className="space-y-2">
      <label htmlFor={identifier}>{labelValue}</label>
      <form className="flex rounded-lg duration-200" onSubmit={handleSubmit}>
        {children}
      </form>
      {error && <p className="text-red-500">{error}</p>}
    </div>
  );
}
