export default function ResultItem({ result, handleClick }) {
  return (
    <article
      onClick={handleClick}
      className="flex flex-col space-y-4 border rounded-lg py-2 px-4 cursor-pointer">
      <h5 className="font-semibold text-lg">{result.title}</h5>
      <div className="flex justify-between">
        <p>{result.identifier}</p>
        <p>{result.length}</p>
      </div>
    </article>
  );
}
