export function agregarCero(num) {
  return num < 10 ? "0" + num : num;
}

export function formatDate(dateString) {
  if (!dateString) return "";
  const date = new Date(dateString);
  // Formatear la fecha como mm-dd-aaaa
  const newDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  // reemplazar las barras por guiones
  return newDate.replace(/\//g, "-");
}
