import { BrowserRouter as Router } from "react-router-dom";
import NotLoggedInRoutes from "./routes/NotLoggedInRoutes";
import LoggedInRoutes from "./routes/LoggedInRoutes";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { Toaster } from "react-hot-toast";
import { initAxiosInterceptors } from "./utils/api";
import CookiesNotice from "./components/ui/CookiesNotice";
import useLoadUser from "./hooks/useLoadUser";

initAxiosInterceptors();

export default function App() {
  const {
    userInformation,
    profileImage,
    hasAgreed,
    setHasAgreed,
    handleLogout,
    setProfileImage,
    setUserInformation,
  } = useLoadUser();

  return (
    <>
      <Toaster position="top-center" />
      <Router>
        {userInformation.access_token && userInformation.refresh_token ? (
          <>
            {!hasAgreed && <CookiesNotice setHasAgreed={setHasAgreed} />}
            <Navbar
              clearUser={handleLogout}
              profileImage={profileImage}
              username={
                userInformation.user.name
                  .split(" ")[0]
                  .toUpperCase()
                  .slice(0, 1) +
                userInformation.user.name.split(" ")[0].toLowerCase().slice(1)
              }
            />
            <LoggedInRoutes
              userInformation={userInformation.user}
              profileImage={profileImage}
              setProfileImage={setProfileImage}
            />
            <Footer />
          </>
        ) : (
          <NotLoggedInRoutes setUserInformation={setUserInformation} />
        )}
      </Router>
    </>
  );
}
