import { useState } from "react";
import toast from "react-hot-toast";
import ToolsAside from "../components/ToolsAside";

export default function SequenceCutter() {
  const [sequence, setSequence] = useState("");
  const [values, setValues] = useState({
    start: 0,
    end: 0,
  });
  const [cutSequence, setCutSequence] = useState("");

  function handleSubmit(e) {
    toast.dismiss();
    e.preventDefault();
    if (
      sequence.length > 1 &&
      values.start &&
      values.end &&
      values.start < values.end
    ) {
      setCutSequence(sequence.slice(values.start - 1, values.end));
    } else {
      toast.error("Please insert a valid sequence and range.");
    }
  }

  return (
    <main className="max-w-5xl mx-auto p-8 md:flex md:space-x-6 space-y-6 md:space-y-0 min-h-[59dvh]">
      <ToolsAside id={1} />
      <div className="space-y-10 md:w-3/4">
        <h2 className="text-4xl font-bold">Sequence Cutter</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className=" space-y-4">
            <label htmlFor="fasta" className="font-semibold text-2xl">
              1. Insert your FASTA Sequence
            </label>
            <textarea
              value={sequence}
              onChange={(e) => {
                if (e.target.value.trim() !== "") {
                  const data = e.target.value.toUpperCase().split("\n");
                  const res = data.filter((t) => !t.match(/[^ATCG]/g));
                  if (res.length > 0) setSequence(res.join(""));
                } else if (e.target.value.trim() === "") setSequence("");
                else setSequence((prev) => prev);
                setValues({ start: 0, end: 0 });
                setCutSequence("");
              }}
              name="fasta"
              id="fasta"
              cols="30"
              rows="10"
              className="w-full p-4 border-2 rounded-lg"
              placeholder=">Sequence"
            />
            <span>{`* FASTA Sequence Length: ${sequence.length}`}</span>
          </div>
          <div className="w-full">
            <span className="font-semibold text-2xl">2. Cut from </span>
            <input
              disabled={!sequence || sequence.length < 2}
              type="number"
              step={1}
              min={1}
              max={sequence.length - 1}
              value={values.start || ""}
              onChange={(e) => {
                if (
                  parseInt(e.target.value) < 1 ||
                  isNaN(parseInt(e.target.value))
                ) {
                  setValues({ ...values, start: 0 });
                  return;
                }
                if (parseInt(e.target.value) < sequence.length) {
                  setValues({ start: parseInt(e.target.value), end: 0 });
                }
                setCutSequence("");
              }}
              className="w-32 p-2 border-2 rounded-lg"
              placeholder="Start"
            />
            <span className="font-semibold text-2xl"> to </span>
            <input
              disabled={!values.start || !sequence}
              type="number"
              step={1}
              min={parseInt(values.start) + 1}
              max={sequence.length}
              className="w-32 p-2 border-2 rounded-lg"
              placeholder="End"
              value={values.end || ""}
              onChange={(e) => {
                if (!e.target.value) {
                  setValues({ ...values, end: 0 });
                  return;
                }
                if (
                  parseInt(e.target.value) > sequence.length ||
                  isNaN(parseInt(e.target.value))
                ) {
                  setValues((prev) => ({ ...prev }));
                  return;
                }
                setValues({ ...values, end: parseInt(e.target.value) });
                setCutSequence("");
              }}
            />
          </div>
          <button className="bg-blue-500 rounded-lg px-6 py-3 text-white text-center hover:bg-blue-600 duration-200 w-full">
            Cut Sequence
          </button>
        </form>

        {cutSequence && (
          <div className="w-full border-2 p-4 rounded-xl shadow-xl space-y-6">
            <div className="flex items-center justify-between">
              <h3 className="text-3xl font-bold">Cut Sequence</h3>
              <img
                className="h-9 hover:cursor-pointer duration-200 hover:bg-gray-200 rounded-md p-1 active:bg-gray-300"
                src="https://storage.googleapis.com/gpp4-bucket/rsc/rsc/clipboard.svg"
                alt="clipboard icon to copy cut sequence"
                onClick={() => {
                  navigator.clipboard.writeText(cutSequence);
                  toast.success("Sequence copied to clipboard.");
                }}
              />
            </div>
            <p className="break-words">{cutSequence}</p>
          </div>
        )}
      </div>
    </main>
  );
}
