export default function GoBackButton({ navigate }) {
  return (
    <button
      className="flex space-x-2 duration-200 border rounded-lg py-2 px-4 hover:bg-gray-100 w-fit"
      onClick={() => navigate("/projects")}>
      <img
        src="https://storage.googleapis.com/gpp4-bucket/rsc/rsc/go-back.svg"
        className="h-6 w-6"
        alt="Go back svg icon"
      />
      <span>Go back to projects</span>
    </button>
  );
}
