export default function SearchForm({
  labelValue,
  identifier,
  handleSubmit,
  children,
  error,
}) {
  return (
    <div className="space-y-2">
      <label htmlFor={identifier}>{labelValue}</label>
      <form
        className="flex rounded-lg duration-200 flex-col space-y-4"
        onSubmit={handleSubmit}>
        {children}
      </form>
    </div>
  );
}
