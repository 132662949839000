import modules from "../assets/modules.json";
import ModuleButton from "./ModuleButton";

export default function ModuleSelector({
  projectId,
  selectedModule,
  isLoadingInformation,
}) {
  return (
    <aside className="bg-gray-100 py-12 rounded-xl flex flex-col items-center w-full space-y-9 px-4 md:w-1/3 h-fit border">
      <h3 className="text-3xl font-bold">Modules</h3>

      <section className="w-full space-y-4">
        {selectedModule && (
          <ModuleButton
            content="Project Details"
            path={`?project=${projectId}`}
          />
        )}
        {modules.map(
          (module) =>
            !module.disabled && (
              <ModuleButton
                key={module.short_name}
                content={module.description}
                disabled={isLoadingInformation}
                isSelected={module.short_name === selectedModule}
                path={`?project=${projectId}&module=${encodeURIComponent(
                  module.short_name
                )}`}
              />
            )
        )}
      </section>
    </aside>
  );
}
