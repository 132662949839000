import React from "react";

export default function Home() {
  return (
    <>
      <main className="py-10 px-8 space-y-8 bg-white md:hidden min-h-[59dvh]">
        <h2 className="text-4xl font-bold text-center text-balance">
          Welcome to the future of biotechnology
        </h2>
        <div className="space-y-8">
          <div className="flex-1 p-4 space-y-3 border rounded-xl">
            <h3 className="text-2xl font-bold text-center">
              Simplify Bioinformatic Procedures
            </h3>
            <p className="md:text-lg text-balance text-center">
              The Gene Paradox Platform is the new way of doing science. Unlock
              the power of bioinformatics effortlessly with our seamless and
              updated procedures.
            </p>
          </div>
          <div className="flex-1 p-4 space-y-3 border rounded-xl">
            <h3 className="text-2xl font-bold text-center">
              Easy-to-Use Interface
            </h3>
            <p className="md:text-lg text-balance text-center">
              Navigate complex bioinformatic procedures with ease using our
              intuitive and user-friendly interface. Explore genes or analyze
              microorganisms – our platform streamlines the process for you.
            </p>
          </div>
          <div className="flex-1 p-4 space-y-3 border rounded-xl">
            <h3 className="text-2xl font-bold text-center">
              Cutting-Edge Updates
            </h3>
            <p className="md:text-lg text-balance text-center">
              Stay ahead in your research endeavors with our regularly updated
              procedures. We ensure that you have access to the latest
              advancements in bioinformatics, empowering your studies and
              discoveries.
            </p>
          </div>
        </div>
      </main>
      {/* <!-- Desktop View --> */}
      <main className="hidden py-10 px-8 space-y-8 bg-white md:block min-h-[59dvh]">
        <h2 className="text-4xl font-bold text-center text-balance">
          Welcome to the future of biotechnology
        </h2>
        <section className="flex max-w-7xl items-center justify-center mx-auto space-x-8">
          <div className="space-y-8 w-2/3 xl:space-x-6 xl:space-y-0 xl:flex">
            <div className="flex-1 p-4 space-y-3 border rounded-xl">
              <h3 className="text-2xl font-bold text-center">
                Simplify Bioinformatic Procedures
              </h3>
              <p className="md:text-lg text-balance text-center">
                The Gene Paradox Platform is the new way of doing science.
                Unlock the power of bioinformatics effortlessly with our
                seamless and updated procedures.
              </p>
            </div>
            <div className="flex-1 p-4 space-y-3 border rounded-xl">
              <h3 className="text-2xl font-bold text-center">
                Easy-to-Use Interface
              </h3>
              <p className="md:text-lg text-balance text-center">
                Navigate complex bioinformatic procedures with ease using our
                intuitive and user-friendly interface. Explore genes or analyze
                microorganisms – our platform streamlines the process for you.
              </p>
            </div>
            <div className="flex-1 p-4 space-y-3 border rounded-xl">
              <h3 className="text-2xl font-bold text-center">
                Cutting-Edge Updates
              </h3>
              <p className="md:text-lg text-balance text-center">
                Stay ahead in your research endeavors with our regularly updated
                procedures. We ensure that you have access to the latest
                advancements in bioinformatics, empowering your studies and
                discoveries.
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
