import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../utils/api";
import { toast } from "react-hot-toast";

export default function RecoverPassword() {
  const { code } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (!code) {
      navigate("/login");
      return;
    }
  }, [code, navigate]);

  const [clicked, setClicked] = useState(false);
  const [originalPassword, setOriginalPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [originalPasswordErrors, setOriginalPasswordErrors] = useState([]);
  const [repeatedPasswordErrors, setRepeatedPasswordErrors] = useState([]);

  function validateData() {
    let isValid = true;
    // reset Errors
    setOriginalPasswordErrors([]);
    setRepeatedPasswordErrors([]);

    if (originalPassword.trim() === "" || originalPassword.trim() !== "") {
      if (originalPassword.length < 8) {
        setOriginalPasswordErrors((prevErrors) => [
          ...prevErrors,
          "Must have at least 8 characters",
        ]);
        isValid = false;
      }
      if (!/[a-z]/.test(originalPassword)) {
        setOriginalPasswordErrors((prevErrors) => [
          ...prevErrors,
          "Must have at least one lowercase letter",
        ]);
        isValid = false;
      }
      if (!/[A-Z]/.test(originalPassword)) {
        setOriginalPasswordErrors((prevErrors) => [
          ...prevErrors,
          "Must have at least one uppercase letter",
        ]);
        isValid = false;
      }
      if (!/\d/.test(originalPassword)) {
        setOriginalPasswordErrors((prevErrors) => [
          ...prevErrors,
          "Must have at least one digit",
        ]);
        isValid = false;
      }
      if (!/[@$!%*?&#]/.test(originalPassword)) {
        setOriginalPasswordErrors((prevErrors) => [
          ...prevErrors,
          "Must have at least one special character: @$!%*?&#",
        ]);
        isValid = false;
      }
    }

    if (isValid) {
      if (originalPassword !== repeatedPassword) {
        setOriginalPasswordErrors((prevErrors) => [
          ...prevErrors,
          "Passwords do not match.",
        ]);
        setRepeatedPasswordErrors((prevErrors) => [
          ...prevErrors,
          "Check this one too!",
        ]);
        isValid = false;
      }
    }
    return isValid;
  }

  async function handleSubmit(e) {
    toast.dismiss();
    e.preventDefault();

    if (validateData()) {
      setClicked(true);
      try {
        const token = code;
        const password = originalPassword;
        await toast.promise(
          new Promise((resolve, reject) => {
            setTimeout(() => {
              axios
                .post(
                  `${API_URL}/users/restore_password`,
                  { token, password },
                  {
                    timeout: 5000,
                  }
                )
                .then(resolve)
                .catch(reject);
            }, 2000);
          }),
          {
            pending: "Changing your password...",
            success: "Successfully changed your password",
          }
        );

        setOriginalPasswordErrors([]);
        setRepeatedPasswordErrors([]);
        navigate("/login");
      } catch ({ response }) {
        const errorMessage =
          response && response.data && response.data.detail
            ? response.data.detail
            : "An unexpected error has occured";
        setTimeout(() => toast.error(errorMessage), 500);
      }
    }
  }
  return (
    <section className="min-h-screen bg-gray-100 flex items-center justify-center px-4">
      <form
        className="bg-white rounded-xl shadow-2xl py-8 px-4 space-y-10 w-full md:max-w-2xl"
        onSubmit={handleSubmit}>
        <h1 className="text-black text-center font-bold text-3xl">
          Password Recovery
        </h1>

        <div className="space-y-2">
          <label className="form-label font-semibold" htmlFor="password1">
            New password
          </label>
          <input
            type="password"
            id="password1"
            placeholder="Type your new password here..."
            className={`form-control ${
              originalPasswordErrors.length > 0 ? "is-invalid" : ""
            }`}
            value={originalPassword}
            onChange={({ target }) => setOriginalPassword(target.value)}
          />
          {originalPasswordErrors.length > 0 && (
            <ul className="invalid-feedback">
              {originalPasswordErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          )}
        </div>

        <div className="space-y-2">
          <label className="form-label font-semibold" htmlFor="password2">
            Repeat new password
          </label>
          <input
            type="password"
            id="password2"
            placeholder="Type your new password again..."
            className={`form-control ${
              originalPasswordErrors.length > 0 ? "is-invalid" : ""
            }`}
            value={repeatedPassword}
            onChange={({ target }) => setRepeatedPassword(target.value)}
          />
          {repeatedPasswordErrors.length > 0 && (
            <ul className="invalid-feedback">
              {repeatedPasswordErrors.map((error, index) => (
                <li key={index}>{error}</li>
              ))}
            </ul>
          )}
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-blue-500 text-white font-semibold py-2 px-6 rounded hover:bg-blue-600 hover:-translate-y-1 duration-200 w-full sm:w-1/2"
            disabled={clicked}>
            Change password
          </button>
        </div>
        <p className="text-sm text-center">* Required field</p>
      </form>
    </section>
  );
}
