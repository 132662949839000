import useRetrievePortfolios from "../../hooks/useRetrievePortfolios";
import PortfolioSkeleton from "./ui/PortfolioSkeleton";
import PortfolioCard from "./ui/PortfolioCard";
import CreatePortfolio from "./ui/CreatePortfolio";

export default function Portfolios() {
  const { portfolios, isLoading } = useRetrievePortfolios();

  return (
    <>
      <h2 className="text-center font-bold text-3xl">Module 5</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 py-4 px-2 equal-rows">
        <CreatePortfolio />
        {isLoading ? (
          <PortfolioSkeleton />
        ) : (
          portfolios.length > 0 &&
          portfolios.map((portfolio) => (
            <PortfolioCard key={portfolio.id} portfolio={portfolio} />
          ))
        )}
      </div>
    </>
  );
}
