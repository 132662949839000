export default function SequenceResult({
  result,
  handleDomainIdChange,
  canSelect = false,
}) {
  return (
    <tr>
      <td
        className={`border-2 border-gray-300 py-2 px-1 ${
          canSelect && "text-blue-500 underline cursor-pointer"
        }`}
        onClick={
          canSelect
            ? () =>
                handleDomainIdChange({
                  value: result.alignment_hit_id,
                  error: "",
                })
            : () => {}
        }>
        {result.alignment_hit_id}
      </td>
      <td className="border-2 border-gray-300 py-2 px-1">
        {result.alignment_length}
      </td>
      <td className="border-2 border-gray-300 py-2 px-1">
        {result.alignment_title}
      </td>
      <td className="border-2 border-gray-300 py-2 px-1">
        {result.hsp_expect}
      </td>
      <td className="border-2 border-gray-300 py-2 px-1">{result.identity}</td>
      <td className="border-2 border-gray-300 py-2 px-1">
        {result.query_coverage}
      </td>
    </tr>
  );
}
