export default function SubmitButton({ isLoading }) {
  return (
    <button
      className="bg-blue-500 enabled:hover:bg-blue-600 text-white rounded-lg duration-200 w-fit py-2 px-4 disabled:cursor-not-allowed disabled:opacity-50"
      disabled={isLoading}
      type="submit">
      Save
    </button>
  );
}
