import React from "react";
import Modal from "./Modal";
import { Link } from "react-router-dom";

export default function InputThanks({ handleClose, path = "/" }) {
  return (
    <Modal handleClose={handleClose}>
      <div className="w-full text-center md:flex md:flex-col md:items-center md:justify-center mt-12">
        <h2 className="text-2xl font-bold">Thank you for your input!</h2>
        <br />
        <h5>Your results will be sent to the email you used to log in.</h5>
        <br />
        <div className="flex justify-evenly w-full space-x-4">
          <Link
            to={"/"}
            className="text-white bg-blue-500 py-3 px-8 rounded-xl hover:opacity-70 hover:-translate-y-1 duration-200">
            Go to Home
          </Link>
          <Link
            onClick={() => handleClose()}
            className="text-white bg-blue-500 py-3 px-4 rounded-xl hover:opacity-70 hover:-translate-y-1 duration-200"
            to={path}>
            Submit another input
          </Link>
        </div>
      </div>
    </Modal>
  );
}
