import { useState } from "react";
import CreatePortfolioButton from "./CreatePortfolioButton";
import CreatePortfolioForm from "./CreatePortfolioForm";

export default function CreatePortfolio() {
  const [isCreating, setIsCreating] = useState(false);

  return isCreating ? (
    <CreatePortfolioForm setIsCreating={setIsCreating} />
  ) : (
    <CreatePortfolioButton setIsCreating={setIsCreating} />
  );
}
