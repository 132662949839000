import Skeleton from "../../ui/Skeleton";

export default function PortfolioSkeleton() {
  return Array.from({ length: 11 }).map((_, index) => (
    <article
      key={index}
      className="flex flex-col items-center rounded-lg border-2 border-gray-200 p-4 space-y-4">
      <Skeleton className="h-7 w-full bg-slate-300" />
      <div className="space-y-4 w-full">
        <Skeleton className="h-5 w-3/4 bg-slate-300" />
        <Skeleton className="h-3 w-1/2 bg-slate-300" />
      </div>
    </article>
  ));
}
