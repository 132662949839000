import axios from "axios";
import { useState, useEffect } from "react";
import { API_URL } from "../utils/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";

export default function useRetrieveProjectInfo(setIsLoadingInformation) {
  const [projectDetails, setProjectDetails] = useState(null);
  const [projectNotes, setProjectNotes] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getUserProjects = async () => {
      setIsLoadingInformation(true);
      try {
        const response = await axios.post(
          `${API_URL}/users/get_proyect_info?project_id=${searchParams.get(
            "project"
          )}`
        );
        if (response.status !== 200) throw response;
        else if (response.data?.error === "Project does not belong to user.") {
          response.data.detail = "This project does not exist.";
          throw response;
        } else {
          setIsLoadingInformation(false);
          setProjectDetails(response.data);
          setProjectNotes(response.data.notes);
        }
      } catch (error) {
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error(
              "An error occurred while processing your request, please try again."
            );
        navigate("/projects");
      }
    };
    getUserProjects();
  }, [navigate, searchParams, setIsLoadingInformation]);

  return {
    projectDetails,
    projectNotes,
    setProjectNotes,
  };
}
