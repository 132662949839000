import { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { API_URL } from "../../utils/api";

import SearchForm from "./ui/SearchForm";
import SearchButton from "./ui/SearchButton";
import SequenceCutter from "./SequenceCutter";

export default function DomainSearch({
  domainSearchId,
  domainFasta,
  setDomainFasta,
  setCutSequence,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [interproIdentifier, setInterproIdentifier] = useState("");
  const [searching, setSearching] = useState(false);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    setInterproIdentifier("");
    toast.dismiss();
    toast.success(
      "Organism Id has been set, please scroll down to Domain Search section"
    );
  }, [domainSearchId]);

  useEffect(() => {
    setFinished(false);
  }, [interproIdentifier]);

  useEffect(() => {
    if (searching) return;
    toast.dismiss();
  }, [searching]);

  useEffect(() => {
    if (!finished) return;
  }, [finished]);

  useEffect(() => {
    if (!interproIdentifier || finished) return;
    if (!searching) {
      setSearching(true);
    }
    const interval = setInterval(() => {
      toast.dismiss();
      try {
        const response = axios.post(
          `${API_URL}/interpro/status?job_id=${interproIdentifier}`
        );

        if (response.data && response.data === "FINISHED") {
          clearInterval(interval);
          setSearching(false);
          setFinished(true);
        }
      } catch (error) {
        error?.data?.detail
          ? toast.error(`Error: ${error.data.detail}`)
          : toast.error("An error occurred while searching for domains");
        setSearching(false);
        setInterproIdentifier("");
        clearInterval(interval);
      }
    }, 7500);
    return () => {
      clearInterval(interval);
    };
  }, [interproIdentifier, searching, finished]);

  async function handleDomainSearch(e) {
    toast.dismiss();
    e.preventDefault();
    setIsLoading(true);
    try {
      toast.loading("Searching for domains\n\nThis process may take a while");

      const genbank_response = await axios.get(
        `${API_URL}/genbank/get/{id}?genbank_id=${domainSearchId.value}&db=protein`
      );
      if (genbank_response.status !== 200) throw genbank_response;

      const interpro_response = await axios.post(`${API_URL}/interpro/run`, {
        fasta: genbank_response.data.fasta_sequence,
      });
      if (interpro_response.status !== 200) throw interpro_response;

      setInterproIdentifier(interpro_response.data);
    } catch (error) {
      error?.data?.detail
        ? toast.error(`Error: ${error.data.detail}`)
        : toast.error("An error occurred while searching for domains");
    }
    setIsLoading(false);
  }

  return (
    <article className="space-y-4">
      <h4 className="font-semibold text-2xl" id="domainTitle">
        Domain Search
      </h4>
      <SearchForm
        labelValue={"Organism ID recovered from sequence alignment"}
        identifier={"organismId"}
        handleSubmit={handleDomainSearch}
        error={domainSearchId.error}>
        <input
          className="rounded-l-lg bg-gray-100 p-2 w-full duration-100 focus:border-gray-400 focus:border outline-none"
          type="text"
          id="organismId"
          name="organismId"
          placeholder="Organism ID recovered from sequence alignment"
          value={domainSearchId.value}
          onChange={() => {
            setInterproIdentifier("");
          }}
          disabled
          readOnly
        />
        <SearchButton
          isLoading={isLoading}
          disabled={
            isLoading || interproIdentifier || !domainSearchId.value || finished
          }
        />
      </SearchForm>

      {finished && interproIdentifier && (
        <>
          <p className="border rounded-lg px-2 text-center py-6 text-gray-500 cursor-default">
            Domain search has finished you can view the results{" "}
            <a
              target="_blank"
              rel="noreferrer"
              className="underline text-blue-500 hover:text-blue-600"
              href={`https://www.ebi.ac.uk/interpro/result/InterProScan/${interproIdentifier}`}>
              here
            </a>
          </p>
          <SequenceCutter
            value={domainSearchId.value}
            domainFasta={domainFasta}
            setDomainFasta={setDomainFasta}
            setCutSequence={setCutSequence}
          />
        </>
      )}
    </article>
  );
}
