import { useNavigate, useSearchParams } from "react-router-dom";

export default function PortfolioCard({ portfolio }) {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("project");
  const navigate = useNavigate();

  return (
    <article
      onClick={() =>
        navigate(
          `/project-details?project=${projectId}&module=5&portfolio=${portfolio.id}`
        )
      }
      className="flex flex-col items-center rounded-lg border-2 border-gray-200 p-6 space-y-4 hover:bg-gray-100 duration-200 hover:shadow-xl cursor-pointer transition-all ease-in-out">
      <p className="text-2xl font-semibold text-gray-800">{portfolio.name}</p>
      <div className="text-left w-full space-y-4">
        <p className="text-md font-semibold">
          # of files:{" "}
          <span className="font-normal">
            {portfolio.pdfs ? portfolio.pdfs : "No files have been added yet"}
          </span>
        </p>
        <p className="text-sm font-semibold">
          Last updated:{" "}
          <span className="font-normal">
            {portfolio.updatedAt
              ? portfolio.updatedAt
              : "Project has not been updated"}
          </span>
        </p>
      </div>
    </article>
  );
}
