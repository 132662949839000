export default function CreatePortfolioButton({ setIsCreating }) {
  return (
    <article
      onClick={() => {
        setIsCreating(true);
      }}
      className="bg-blue-500 text-white flex flex-col justify-center items-center rounded-lg text-center p-4 space-y-4 hover:bg-blue-600 duration-200 hover:shadow-xl cursor-pointer">
      <img
        src="https://storage.googleapis.com/gpp4-bucket/rsc/rsc/plus.svg"
        alt="Create New Portfolio"
        className="h-12 w-12"
      />
      <p className="text-2xl font-semibold">Create New Portfolio</p>
    </article>
  );
}
